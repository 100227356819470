import React, {useEffect} from 'react';
// hooks
import {useDispatch, useSelector} from 'react-redux';
// redux
import {getCareerAreaList, fetchCareerAreaList} from 'redux/modules/programs';
import {clearPathSearch, resetInitalLoad} from 'redux/modules/search';
import {getInstanceVersion} from 'redux/modules/instance';
// Components
import {ProgramsClusterArea} from './subModules';
import {Stack, Container, Typography} from '@mui/material';
import ProgramsList from 'mui-core/QualifyingPrograms/components';
import {
  BackgroundPaper,
  CarouselBanner,
  ErrorBoundary,
  ResourceBanner,
} from 'mui-core';
// Utils
import {queryStringParse} from 'core/utils';
import {exploreProgramsBanner} from 'assets/images';
import {alpha, useTheme} from '@mui/material/styles';
import {explorePrograms} from 'data/explorePrograms.json';

const {
  subHeading1,
  subHeading2,
  bannerHeading,
  takeSurveyBtn,
} = explorePrograms;

const EducationPrograms = ({history}) => {
  // hooks
  const theme = useTheme();
  const dispatch = useDispatch();
  const careerAreaList = useSelector(getCareerAreaList);
  const {programs_page: {survey_button = false} = {}} =
    useSelector(getInstanceVersion) || {};
  // others
  const {
    location: {search},
  } = history;
  const {type} = queryStringParse(search);

  useEffect(() => {
    if (!careerAreaList?.request && !careerAreaList?.data) {
      dispatch(fetchCareerAreaList());
    }
    if (type) {
      window.scrollTo({
        behavior: 'smooth',
        top: !careerAreaList?.data ? 668 : 1070,
      });
    }
    return () => {
      dispatch(clearPathSearch());
      dispatch(resetInitalLoad());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BackgroundPaper background={theme.palette.purplebg4perc.main}>
      {/* <Container> */}
      <ErrorBoundary nameOfComponent='home-educationprograms'>
        <Container>
          <ResourceBanner
            title={bannerHeading}
            bannerImage={exploreProgramsBanner}
            buttonLabel={survey_button ? '' : takeSurveyBtn}
            onButtonClick={() => history.push('/GOSurvey')}>
            <Stack spacing={2}>
              <Typography lineHeight={2} variant='pagedesc'>
                {subHeading1}
              </Typography>
              <Typography lineHeight={2} variant='pagedesc'>
                {subHeading2}
              </Typography>
            </Stack>
          </ResourceBanner>
          <ProgramsList
            // fixedScrollHeight={700}
            careerAreaList={careerAreaList}
            title='Explore College & Training Programs'
          />
          {/* <Stack spacing={1} mt={{xs: 4, sm: 4, md: 0}}>
            <ProgramsClusterArea careerAreaList={careerAreaList} />
            <ProgramsList
              // fixedScrollHeight={1065}
              careerAreaList={careerAreaList}
              title='In-Demand, Popular and New Programs'
            />
          </Stack> */}
        </Container>
      </ErrorBoundary>
      {/* </Container> */}
    </BackgroundPaper>
  );
};

export default EducationPrograms;
