/**
 * Takes location search string.
 * @memberOf Utils
 *
 * @param   {string} search
 *
 * @returns {object} of url parameters
 */

import moment from 'moment';
import jwtDecode from 'jwt-decode';
import {isString, isArray, isObject, isEmpty as checkEmpty, uniq} from 'lodash';
import {
  FileOutlined,
  FileJpgOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileExcelOutlined,
  FileMarkdownOutlined,
  FileWordOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import {
  noCollageLogo,
  defaultoccupation,
  defaultbannerprofile,
  defaultEvent,
  noPathwayBanner,
} from 'assets/images';
import {AppRoutes, IMG_CLOUDINARY} from 'config';

function millisToMinutesAndSeconds(millis) {
  if (millis > 60000) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return {
      minutes: parseInt(minutes),
      seconds: parseInt(seconds),
    };
  }
  return {
    minutes: 0,
    seconds: 0,
  };
  //return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

export const isTokenExpire = token => {
  let Obj = {
    expiringInMins: 0,
    isExpired: false,
  };

  if (token) {
    const tokenExpiration = jwtDecode(token).exp;
    const dateNow = new Date();
    const expTime = new Date(tokenExpiration * 1000).getTime();
    const currentTime = dateNow.getTime();
    const timeDiff = expTime - currentTime;
    const {minutes} = millisToMinutesAndSeconds(timeDiff);
    Obj = {
      ...Obj,
      expiringInMins: minutes,
      isExpired: minutes < 2,
    };
  }
  return Obj;
};

export const getCompleteDate = dmy => {
  let year = null,
    month = null,
    date = null;
  if (dmy && isString(dmy)) {
    let d = new Date(dmy);
    year = d && d.getFullYear();
    month = d && d.getMonth() + 1;
    date = d && d.getDate();
  }
  return {
    date,
    month,
    year,
  };
};

export const isEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const isNumber = value => typeof value === 'number' && isFinite(value);

export const queryStringParse = data =>
  data
    .substring(1)
    .split('&')
    .map(item => {
      const idx = item.indexOf('=');
      const variableName = item.substring(0, idx);
      const variableValue = item.substring(idx + 1);
      return Object.assign(
        {},
        {
          [variableName]: variableValue,
        },
      );
    })
    .reduce(function (result, current) {
      return Object.assign(result, current);
    }, {});

export const formatNoOfUnits = data => {
  if (data && data.includes('.')) {
    const arr = data.split('.');
    const beforeDecimal = arr[0];
    const afterDecimal = arr[1];
    if (afterDecimal === '0' || afterDecimal === '00') {
      return beforeDecimal;
    }
    if (afterDecimal.endsWith('0')) {
      const uptoOnedecimal = afterDecimal.split()[0];
      return `${beforeDecimal}.${uptoOnedecimal}`;
    }
  }
  return data;
};

export const diplayRules = (onlySegments, item) => {
  if (onlySegments) {
    const {rule_type, number_of_required_course, rules} = item;
    if (rules) {
      return Object.keys(rules)?.map((rule, idx) => {
        if (rules[rule] && rule === 'no_of_courses') {
          return (
            <h5 className='courseNumberInfo mb-1 px-3 py-1' key={`rule-${idx}`}>
              Rule: Choose {rules[rule] || 0} course(s)
            </h5>
          );
        } else if (rules[rule] && rule === 'no_of_units') {
          return (
            <h5 className='courseNumberInfo mb-1 px-3 py-1' key={`rule-${idx}`}>
              Rule: Complete {rules[rule] || 0} unit(s)
            </h5>
          );
        }
      });
    }
    return (
      <h5 className='px-3 py-1 courseNumberInfo mb-0 rule-1'>
        {rule_type && rule_type == 'Complete number of unit(s)'
          ? `Rule: Complete ${formatNoOfUnits(
              number_of_required_course || 0,
            )}  unit(s)`
          : `Rule: Choose ${number_of_required_course || 0} course(s)`}
      </h5>
    );
  } else {
    const {rules} = item;
    return Object.keys(rules).map((rule, idx) => {
      if (rules[rule] && rule === 'no_of_courses') {
        return (
          <h5 className='courseNumberInfo mb-1 px-3 py-1' key={`rule-${idx}`}>
            Rule: Choose {rules[rule] || 0} course(s)
          </h5>
        );
      } else if (rules[rule] && rule === 'no_of_units') {
        return (
          <h5 className='courseNumberInfo mb-1 px-3 py-1' key={`rule-${idx}`}>
            Rule: Complete {rules[rule] || 0} unit(s)
          </h5>
        );
      } else if (
        rules[rule] &&
        rule === 'no_of_courses_from_segment' &&
        rules[rule].length
      ) {
        return rules[rule].map((subRule, subIndex) => (
          <h5
            className='courseNumberInfo mb-1 px-3 py-1'
            key={`sub-rule-${subIndex}`}>
            Rule: Choose {subRule.no_of_courses || 0} course(s) from{' '}
            {subRule.title}
          </h5>
        ));
      } else if (
        rules[rule] &&
        rule === 'no_of_units_from_segment' &&
        rules[rule].length
      ) {
        return rules[rule].map((subRule, subIndex) => (
          <h5
            className='courseNumberInfo mb-1 px-3 py-1'
            key={`sub-rule-${subIndex}`}>
            Rule: Complete {subRule.no_of_units || 0} unit(s) from{' '}
            {subRule.title}
          </h5>
        ));
      } else if (
        rules[rule] &&
        //!isEmpty(rules[rule]) &&
        rule === 'no_of_courses_from_no_of_segment'
      ) {
        return (
          <h5 className='courseNumberInfo mb-1 px-3 py-1' key={`rule-${idx}`}>
            Rule: Choose {rules[rule].no_of_courses || 0} course(s) from atleast{' '}
            {rules[rule].no_of_segments || 0} segment(s)
          </h5>
        );
      } else if (
        rules[rule] &&
        // !isEmpty(rules[rule]) &&
        rule === 'no_of_units_from_no_of_segment'
      ) {
        return (
          <h5 className='courseNumberInfo mb-1 px-3 py-1' key={`rule-${idx}`}>
            Rule: Choose {rules[rule].no_of_units || 0} units(s) from atleast{' '}
            {rules[rule].no_of_segments || 0} segment(s)
          </h5>
        );
      }
    });
  }
};

export const prepareCourseTableData = (data, key) => {
  if (data && data[key] && Array.isArray(data[key]) && data[key].length > 0) {
    return data[key].map(course => ({
      key: course.uuid,
      ...course,
    }));
  } else {
    return [];
  }
};

export const prepareTableData = (data, key) => {
  if (data && Array.isArray(data) && data.length > 0) {
    return data.map(d => ({
      key: d[key],
      ...d,
    }));
  } else {
    return [];
  }
};

export const formatCount = count => {
  if (count === 0 || count === undefined || count === null) return 0;
  count = parseFloat(count);
  let a = count; //count.toFixed(1);
  let b = String(a).split('.');
  if (b[1] === 0) a = b[0];
  return a;
};

export const calculateCourseUnits = items => {
  let count = 0;
  try {
    items &&
      Array.isArray(items) &&
      items.forEach(c => {
        if (c.units) {
          count =
            Math.round((parseFloat(count) + parseFloat(c.units)) * 100) / 100;
        }
      });
    try {
      return formatCount(count);
    } catch (e) {
      return count;
    }
  } catch (err) {
    return 0;
  }
};

const customSort = (property, order) => {
  var sortOrder = 1;
  if (order === 'descending') {
    sortOrder = -1;
  }

  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};
export const newSortArrayOfObjPropertyBased = (obj, propName, order) => {
  return (
    obj &&
    [...obj]
      .map(item => {
        item['units'] = parseFloat(item['units']);
        return item;
      })
      .sort(customSort(propName, order))
  );
};

const dynamicSort = property => {
  var sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    if (sortOrder == -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
};

export const sortArrayOfObjPropertyBased = (obj, propName) => {
  return obj && [...obj].sort(dynamicSort(propName));
};

// used to filter documents from list
export const getFilesAndTotalSize = (filesData, type) => {
  let data = [];
  let total_size_consumed = 0;
  if (
    type &&
    filesData &&
    filesData.documents &&
    isArray(filesData.documents) &&
    !checkEmpty(filesData.documents)
  ) {
    data = filesData.documents.filter(file => {
      if (file.doc_type === type) {
        total_size_consumed += file.doc_size_kb || 0;
        return file;
      }
    });
  }
  return {
    data,
    total_size_consumed: total_size_consumed
      ? (total_size_consumed / 1000).toFixed(2)
      : 0,
  };
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return {
    converted_size: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    format: sizes[i],
  };
};

export const bytesToMegaBytes = (bytes, roundTo = 2) => {
  var converted = bytes / (1024 * 1024);
  return roundTo ? converted.toFixed(roundTo) : converted;
};

// Specific to pathways calculations

export const getGraphData = data => {
  const {
    terms,
    path: {
      total_units_required,
      req_courses: required_courses,
      segment_groups,
      segment,
    } = {},
  } = data;
  const req_courses = required_courses || segment_groups || [];
  let total = 0;
  let totalUnits = 0;
  let totalUnitsPercent = 0;
  let requiredCoures = 0;
  let requiredCouresPercent = 0;
  let termsCompletedReqCourses = 0;
  let termsCompletedReqCoursesPercent = 0;

  if (terms && terms.length > 0) {
    terms.forEach(t => {
      t.data.forEach(({units}) => {
        totalUnits += parseFloat(units || 0);
      });
    });

    // if (req_courses && req_courses.length > 0) {
    //   total += req_courses.length;
    //   req_courses.forEach(course => {
    //     for (let i = 0; i < terms.length; i++) {
    //       const {data} = terms[i];
    //       const exists = data.filter(d => d.course_id === course.course_id)[0];
    //       if (exists) requiredCoures++;
    //     }
    //   });
    //   requiredCouresPercent = (100 * requiredCoures) / req_courses.length;
    // }

    if (segment && segment.length > 0) {
      total += segment.length;
      segment.forEach(s => {
        const {courses, number_of_required_course, rule_type} = s || {};
        let noOfCoursesAdded = 0;
        courses &&
          courses.length > 0 &&
          courses.forEach(course => {
            for (let i = 0; i < terms.length; i++) {
              const {data} = terms[i];
              const exists = data.filter(
                d => d.course_id === course.course_id,
              )[0];
              if (exists) {
                if (rule_type === 'Complete number of unit(s)') {
                  noOfCoursesAdded += Number(course.units);
                } else {
                  noOfCoursesAdded++;
                }
              }
            }
          });
        if (noOfCoursesAdded >= parseInt(number_of_required_course))
          termsCompletedReqCourses++;
        return false;
      });
      termsCompletedReqCoursesPercent =
        (100 * termsCompletedReqCourses) / segment.length;
    }
  }

  if (totalUnits > 0 && total_units_required !== undefined) {
    totalUnitsPercent = (100 * totalUnits) / parseFloat(total_units_required);
  }
  const totalCoreCourses = requiredCoures + termsCompletedReqCourses;
  const totalCoreCouresPercent = (totalCoreCourses * 100) / total;
  return {
    total,
    totalCoreCourses,
    totalCoreCouresPercent,
    termsCompletedReqCourses,
    termsCompletedReqCoursesPercent,
    requiredCoures,
    requiredCouresPercent,
    totalUnits: formatCount(totalUnits.toFixed(2)),
    totalUnitsPercent: formatCount(totalUnitsPercent),
  };
};

export const getCoreUnitsGraphData = (terms, path) => {
  const {
    completed: genEdCompleted,
    total: genEdTotal,
    perc: genEdCompletedPerc,
  } = validateGenEduPlanned(path, terms);
  return {
    total: genEdTotal,
    completed: genEdCompleted,
    perc: genEdCompletedPerc,
  };
};

export const getCombinationsForK = (length, k) => {
  var combi = [];
  var temp = [];
  const valuesArray = new Array(length).fill(0).map((it, i) => i);
  var slent = Math.pow(2, valuesArray.length);
  for (var i = 0; i < slent; i++) {
    temp = [];
    for (var j = 0; j < valuesArray.length; j++) {
      if (i & Math.pow(2, j)) {
        temp.push(valuesArray[j]);
      }
    }
    if (temp.length > 0) {
      combi.push(temp);
    }
  }
  combi.sort((a, b) => a.length - b.length);
  return combi.filter(i => i.length === k);
};

export const checkCourseAdded = (course, terms) => {
  if (terms && terms.length) {
    for (let i = 0; i < terms.length; i++) {
      const {data} = terms[i];
      const exists = data.filter(d => d.course_id === course.course_id)[0];
      if (exists) return true;
    }
  }
  return false;
};

export const checkAllCoreCourseAdded = (coreCourses, terms) => {
  let allCoreCoursesAdded = [];
  if (coreCourses && coreCourses.length && terms && terms.length) {
    coreCourses.map(course => {
      terms.map(term => {
        const {data} = term;
        const exists = data.filter(d => d.course_id === course.course_id)[0];
        if (exists) {
          allCoreCoursesAdded.push(true);
        }
      });
    });
  }
  return allCoreCoursesAdded;
};

export const validateAdditionalCoreCourses = (segments, terms) => {
  const segmentValidation = [];
  if (Array.isArray(segments)) {
    segments.forEach(segment => {
      let validSegment = true;
      const segmentErr = {};
      const {courses, rule_type, number_of_required_course} = segment;
      let noOfCourses = 0;
      let noOfUnits = 0;
      if (Array.isArray(courses)) {
        courses.forEach(c => {
          const isExist = checkCourseAdded(c, terms);
          if (isExist) {
            noOfCourses++;
            noOfUnits += parseFloat(c.units);
          }
        });
      }
      if (rule_type === 'Choose number of course(s)') {
        if (parseFloat(number_of_required_course) <= noOfCourses) {
          segmentErr['no_of_courses'] = true;
        } else {
          segmentErr['no_of_courses'] = false;
          validSegment = false;
        }
      }
      if (rule_type === 'Complete number of unit(s)') {
        if (parseFloat(number_of_required_course) <= parseFloat(noOfUnits)) {
          segmentErr['no_of_units'] = true;
        } else {
          segmentErr['no_of_units'] = false;
          validSegment = false;
        }
      }
      segmentValidation.push({
        valid: validSegment,
        rules: segmentErr,
      });
    });
  }
  return segmentValidation;
};
export const validateGenEduPlanned = (gened, terms) => {
  if (!gened) return {};
  const {segments: segs, segment_groups, segment} = gened || {};
  const segments = segs || segment || [];
  let completed = 0;
  const segmentValidation = [];
  const segmentGroupsValidation = [];
  if (Array.isArray(segments)) {
    segments.forEach(segment => {
      let validSegment = true;
      const segmentErr = {};
      const {courses, rules} = segment;
      let noOfCourses = 0;
      let noOfUnits = 0;
      if (Array.isArray(courses)) {
        courses.forEach(c => {
          const isExist = checkCourseAdded(c, terms);
          if (isExist) {
            noOfCourses++;
            noOfUnits = noOfUnits + parseFloat(c.units);
          }
        });
      }
      if (rules && rules.hasOwnProperty('no_of_courses')) {
        if (rules['no_of_courses'] <= noOfCourses) {
          segmentErr['no_of_courses'] = true;
        } else {
          segmentErr['no_of_courses'] = false;
          validSegment = false;
        }
      }
      if (rules && rules.hasOwnProperty('no_of_units')) {
        if (rules['no_of_units'] <= parseFloat(noOfUnits)) {
          segmentErr['no_of_units'] = true;
        } else {
          segmentErr['no_of_units'] = false;
          validSegment = false;
        }
      }
      if (validSegment) completed += 1;
      segmentValidation.push({
        valid: validSegment,
        rules: segmentErr,
      });
    });
  }
  if (Array.isArray(segment_groups)) {
    segment_groups.forEach(segGrp => {
      const {rules, segments} = segGrp;
      let segGrpValid = true;
      let segGrpErros = {};
      let noOfUnits = 0;
      let noOfCourses = 0;
      if (Array.isArray(segments)) {
        segments.forEach(seg => {
          if (Array.isArray(seg.courses)) {
            seg.courses.forEach(c => {
              const exists = checkCourseAdded(c, terms);
              if (exists) {
                noOfCourses++;
                noOfUnits += parseFloat(c.units);
              }
            });
          }
        });
      }
      if (rules && rules.hasOwnProperty('no_of_courses')) {
        if (rules['no_of_courses'] <= noOfCourses) {
          segGrpErros['no_of_courses'] = true;
        } else {
          segGrpErros['no_of_courses'] = false;
          segGrpValid = false;
        }
      }
      if (rules && rules.hasOwnProperty('no_of_units')) {
        if (rules['no_of_units'] <= parseFloat(noOfUnits)) {
          segGrpErros['no_of_units'] = true;
        } else {
          segGrpErros['no_of_units'] = false;
          segGrpValid = false;
        }
      }
      if (
        rules &&
        rules.hasOwnProperty('no_of_courses_from_segment') &&
        Array.isArray(rules['no_of_courses_from_segment'])
      ) {
        segGrpErros['no_of_courses_from_segment'] = [];
        rules['no_of_courses_from_segment'].forEach(r => {
          const {no_of_courses, title} = r;
          const _seg = segments.filter(
            i => (i.title || i.segment_name) === title,
          )[0];
          if (_seg) {
            const {courses} = _seg;
            let _noOfCourses = 0;
            if (Array.isArray(courses)) {
              courses.forEach(__c => {
                const exists = checkCourseAdded(__c, terms);
                if (exists) _noOfCourses++;
              });
            }
            if (no_of_courses <= _noOfCourses) {
              segGrpErros['no_of_courses_from_segment'].push(true);
            } else {
              segGrpErros['no_of_courses_from_segment'].push(false);
              segGrpValid = false;
            }
          } else {
            segGrpErros['no_of_courses_from_segment'].push(false);
            segGrpValid = false;
          }
        });
      }
      if (
        rules &&
        rules.hasOwnProperty('no_of_units_from_segment') &&
        Array.isArray(rules['no_of_units_from_segment'])
      ) {
        segGrpErros['no_of_units_from_segment'] = [];
        rules['no_of_units_from_segment'].forEach(r => {
          const {no_of_units, title} = r;
          const _seg = segments.filter(
            i => (i.title || i.segment_name) === title,
          )[0];
          if (_seg) {
            const {courses} = _seg;
            let _noOfUnits = 0;
            if (Array.isArray(courses)) {
              courses.forEach(__c => {
                const exists = checkCourseAdded(__c, terms);
                if (exists) _noOfUnits += parseFloat(__c.units);
              });
            }
            if (no_of_units <= parseFloat(_noOfUnits)) {
              segGrpErros['no_of_units_from_segment'].push(true);
            } else {
              segGrpErros['no_of_units_from_segment'].push(false);
              segGrpValid = false;
            }
          } else {
            segGrpErros['no_of_units_from_segment'].push(false);
            segGrpValid = false;
          }
        });
      }
      if (rules && rules.hasOwnProperty('no_of_courses_from_no_of_segment')) {
        const {no_of_courses, no_of_segments} = rules[
          'no_of_courses_from_no_of_segment'
        ];
        const segmentCombinations = getCombinationsForK(
          segments.length,
          no_of_segments,
        );
        let isValid = false;
        let segmentsHasCourses = 0;
        Array.isArray(segmentCombinations) &&
          segmentCombinations.every(_segments => {
            let noOfCourses = 0;
            _segments.every(_segIndex => {
              let _no_of_courses = 0;
              segments[_segIndex].courses.forEach(c => {
                const exists = checkCourseAdded(c, terms);
                if (exists) {
                  _no_of_courses += 1;
                }
              });
              noOfCourses += _no_of_courses;
              if (_no_of_courses > 0) segmentsHasCourses += 1;
              return true;
            });
            if (
              noOfCourses >= no_of_courses &&
              segmentsHasCourses >= no_of_segments
            ) {
              isValid = true;
              return false;
            } else {
              segGrpValid = false;
            }
            return true;
          });
        segGrpErros['no_of_courses_from_no_of_segment'] = isValid;
      }
      if (rules && rules.hasOwnProperty('no_of_units_from_no_of_segment')) {
        const {no_of_units, no_of_segments} = rules[
          'no_of_units_from_no_of_segment'
        ];
        const segmentCombinations = getCombinationsForK(
          segments.length,
          no_of_segments,
        );
        let isValid = false;
        let segmentsHasUnits = 0;
        Array.isArray(segmentCombinations) &&
          segmentCombinations.every(_segments => {
            let noOfUnits = 0;
            _segments.every(_segIndex => {
              let _no_of_units = 0;
              segments[_segIndex].courses.forEach(c => {
                const exists = checkCourseAdded(c, terms);
                if (exists) {
                  _no_of_units += parseFloat(c.units);
                }
              });
              noOfUnits += parseFloat(_no_of_units);
              if (_no_of_units > 0) segmentsHasUnits += 1;
              return true;
            });
            if (
              noOfUnits >= no_of_units &&
              segmentsHasUnits >= no_of_segments
            ) {
              isValid = true;
              return false;
            } else {
              segGrpValid = false;
            }
            return true;
          });
        segGrpErros['no_of_units_from_no_of_segment'] = isValid;
      }
      if (segGrpValid) completed += 1;
      segmentGroupsValidation.push({
        valid: segGrpValid,
        rules: segGrpErros,
      });
    });
  }
  let total = 0;
  total = segments?.length + segment_groups?.length;
  const perc = (100 * completed) / total;
  return {
    segmentValidation,
    segmentGroupsValidation,
    completed,
    total,
    perc,
  };
};

export const formatViewDate = date => {
  try {
    const dayObj = moment(new Date(date));
    return dayObj.format('MM/DD/YYYY');
  } catch (e) {
    return '';
  }
};

export const formatPhoneNumber = phoneNumber => {
  try {
    let phoneNumberString = phoneNumber.toString();
    return (
      phoneNumberString?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') || '-'
    );
  } catch (e) {
    return '-';
  }
};

//return ModuleBasePath
export const getModuleBasePath = moduleName => {
  return AppRoutes.filter(({key}) => key === moduleName)[0].path;
};

export const calculateNoOfRecordsFetched = (OBJ, defaultPageZize) => {
  const {totalRecords: total, page: currentPage, length: currentPageLength} =
    OBJ || {};
  let NoOfRecFetch = currentPageLength;
  if (currentPage > 1 && total > defaultPageZize) {
    const NoOfPagesDone = currentPage - 1;
    NoOfRecFetch += NoOfPagesDone * defaultPageZize;
  }
  return NoOfRecFetch;
};

// Used to format duration string in pathways
export const durationString = data => {
  if (data) {
    const durationKeys = ['year', 'month', 'week', 'day', 'hour'];
    let displayString = '';
    durationKeys.map((key, idx) => {
      const composed_key = `duration_${key}s`;
      let DATA = data[composed_key];
      if (DATA) {
        // added to remove unnecessary zero in decimal
        try {
          DATA = Number(DATA);
        } catch (err) {}
        const dispKey = `${key[0].toUpperCase()}${key.slice(1)}(s)`;
        displayString += `${DATA} ${dispKey}`;
      }
    });
    return displayString;
  }
  return '';
};

export const fileTypeIcon = fileType => {
  const supportedTypesIcon = {
    file: FileOutlined,
    jpg: FileJpgOutlined,
    jpeg: FileJpgOutlined,
    png: FileImageOutlined,
    gif: FileGifOutlined,
    pdf: FilePdfOutlined,
    txt: FileOutlined,
    md: FileMarkdownOutlined,
    doc: FileWordOutlined,
    docx: FileWordOutlined,
    xls: FileExcelOutlined,
    csv: FileExcelOutlined,
    ppt: FilePptOutlined,
    mp4: VideoCameraOutlined,
    '3gp': VideoCameraOutlined,
    'application/pdf': 'file-pdf',
    'image/jpeg': 'file-jpg',
    'image/png': 'file-jpg',
    'text/plain': 'file-text',
  };

  if (fileType && isString(fileType) && fileType.includes('.')) {
    fileType = fileType.split('.').pop();
  }
  const typeFile = fileType && fileType.toLowerCase();
  return supportedTypesIcon[typeFile] || supportedTypesIcon['file'];
};

/**
 * ======================== Cloudinary Images ====================================================
 * Following finctions are used to get the transpromed images based on UI requirements:
 * a) getLogo ->  use to get the logo
 * b) getWhiteLogo ->  used to convert the logo colors into white
 * c) getCardImage -> used to get the card size image
 * d) getBanner ->  used to get banner image
 * e) getProfilePhoto ->  used to get profile photo
 * f) getCardImgSet ->  used to get the card size image set
 *
 *
 * dpr_${dpr},e_auto_brightness,e_sharpen:${e_sharpen}
 * Effects:
 * e_sharpen:{value} =  1-2000 (100)
 * e_brightness:{value} =   -99 - 100 (100)
 * e_vibrance:{value}  = -100 - 100 (20)
 * e_vectorize
 * e_transition
 * e_brightness_hsb
 * e_auto_brightness
 * e_auto_contrast
 * e_auto_color
 * e_auto_saturation
 *
 *
 * Quality:
 * q_{value} =  auto, 0-100
 *
 * DPR:- Modify the image scaling using the given Device Pixel Ratio.
 * dpr_{value} =  auto, 0.75, 1, 1.3, 1.5, 2.0, 3.0, 3.5, 4.0
 * ======================================================================================
 */

export const getLogo = (publicID, ext) =>
  `${IMG_CLOUDINARY}/q_60/d_defaultlogoimage_zot4ua.svg/${publicID}${
    ext ? '.${ext}' : ''
  }`;

export const getWhiteLogo = publicID =>
  `${IMG_CLOUDINARY}/e_colorize,co_rgb:ffffff,q_auto/d_defaultlogoimage_zot4ua.svg/${publicID}.png`;

export const getProfilePhoto = publicID =>
  publicID
    ? `${IMG_CLOUDINARY}/ar_5:6,c_thumb,g_face:auto,h_100,q_100,r_max,w_100/d_profile_default_htkxlw.png/${publicID}.png`
    : null;

export const getCardImage = publicID =>
  publicID
    ? `${IMG_CLOUDINARY}/ar_5.6,c_fill,dpr_1.0,e_sharpen,e_auto_brightness,g_auto,h_150,q_60,w_250/y_-0.5/${publicID}.jpg`
    : null;

export const getCardImgSet = (
  publicID,
  type,
  config = {
    height: 150,
    width: 250,
    c_prop: 'fill',
    g_prop: 'auto',
    noConfigNeeded: false,
    ar_prop: false,
  },
) => {
  publicID = encodeURIComponent(publicID);
  let defaultImage = `${IMG_CLOUDINARY}/q_auto/defaultCard_xdoa5p.png`; //noPathwayBanner - searchresultdefault_new_oznis2
  const defaultBriefcaseBanner = `${IMG_CLOUDINARY}/briefcasedefault_new_g2t9v6.svg`;
  // const defaultBookmarkBanner = `${IMG_CLOUDINARY}/bookmarkdefault_new_j8qee9.svg`;
  // const defaultEventBanner = `${IMG_CLOUDINARY}/eventdefault_new_xobjac.svg`;
  // if (type && type === 'occupation') {
  //   defaultImage = defaultBookmarkBanner;
  // }
  if (type && type == 'appliedWorkforce') {
    defaultImage = defaultBriefcaseBanner;
  }
  // if (type && type === 'event') {
  //   defaultImage = defaultEventBanner;
  // }
  // if (type && type === 'bp') {
  //   defaultImage = noCollageLogo;
  // }

  let srcSet = [];
  if (publicID) {
    let fallBack = 'd_defaultCard_xdoa5p.png'; // 'd_defaultCard_gbaner.svg'; d_searchresultdefault_new_oznis2
    // if (type && type === 'occupation') {
    //   fallBack = 'd_bookmarkdefault_new_j8qee9.svg';
    // }
    if (type && type == 'appliedWorkforce') {
      fallBack = 'd_briefcasedefault_new_g2t9v6.svg';
    }
    // if (type && type === 'event') {
    //   fallBack = 'd_eventdefault_new_xobjac.svg';
    // }

    const sizes = ['', 480, 960, 1440];
    for (let i = 1; i <= 3; i++) {
      let configString = `q_auto,dpr_${i}.0,h_${config?.height},w_${config?.width}`;
      let path = `${IMG_CLOUDINARY}/c_${config?.c_prop},g_${config?.g_prop},${configString}/${fallBack}/${publicID}.jpg ${sizes[i]}w`;
      if (!config?.c_prop && !config?.g_prop) {
        path = `${IMG_CLOUDINARY}/${configString}/${fallBack}/${publicID}.jpg ${sizes[i]}w`;
      }
      if (!config?.c_prop) {
        path = `${IMG_CLOUDINARY}/g_${config?.g_prop},${configString}/${fallBack}/${publicID}.jpg ${sizes[i]}w`;
      }
      if (!config?.g_prop) {
        path = `${IMG_CLOUDINARY}/c_${config?.c_prop},${configString}/${fallBack}/${publicID}.jpg ${sizes[i]}w`;
      }
      if (config?.ar_prop) {
        path = `${IMG_CLOUDINARY}/c_${config?.c_prop},dpr_${i}.0,q_auto,ar_${config?.ar_prop}/${fallBack}/${publicID}.jpg ${sizes[i]}w`;
      }
      if (config.noConfigNeeded) {
        path = `${IMG_CLOUDINARY}/q_auto/${fallBack}/${publicID}.jpg ${sizes[i]}w`;
      }
      srcSet.push(path);
    }
    defaultImage = `${IMG_CLOUDINARY}/c_fill,e_blur:75,q_0.5,w_20/${fallBack}/${publicID}.jpg`;
  }
  return {
    srcSet: srcSet.join(','),
    normalImage: (srcSet?.length && srcSet[1]) || '',
    defaultImage,
  };
};

export const getBannerWithConfig = (publicID, config) => {
  const {height = 250, width = 1250, quality = 60, dpr = '2.0'} = config || {};
  let configString = `c_fill,g_auto,h_${height},q_${quality},dpr_${dpr},w_${width}`;
  if (config && isObject(config)) {
    Object.keys(config).map(key => {
      if (
        key === 'e_brightness' ||
        key === 'e_auto_contrast' ||
        key === 'e_auto_color' ||
        key === 'e_auto_saturation'
      ) {
        const property = key.substring(2);
        if (config[key] === 'auto') {
          configString += `,e_auto_${property}`;
        } else {
          configString += `,e_${property}:${config[key]}`;
        }
      }
      if (key === 'e_sharpen') {
        configString += `,e_sharpen:${config[key]}`;
      }
      if (key === 'e_vibrance') {
        configString += `e_vibrance:${config[key]}`;
      }
    });
    return `${IMG_CLOUDINARY}/${configString}/y_-0.5/${publicID}`;
  }
};

export const getBanner = (publicID, config) => {
  const {height = 250, width = 1250, quality = 60, dpr = '2.0'} = config || {};
  let configString = `c_fill,g_auto,h_${height},q_${quality},dpr_${dpr},w_${width}`;
  if (config && isObject(config)) {
    Object.keys(config).map(key => {
      if (
        key === 'e_brightness' ||
        key === 'e_auto_contrast' ||
        key === 'e_auto_color' ||
        key === 'e_auto_saturation'
      ) {
        const property = key.substring(2);
        if (config[key] === 'auto') {
          configString += `,e_auto_${property}`;
        } else {
          configString += `,e_${property}:${config[key]}`;
        }
      }
      if (key === 'e_sharpen') {
        configString += `,e_sharpen:${config[key]}`;
      }
      if (key === 'e_vibrance') {
        configString += `e_vibrance:${config[key]}`;
      }
    });
  }

  if (publicID) {
    return `${IMG_CLOUDINARY}/${configString}/y_-0.5/d_defaultbannerprofile_hnyuqa.jpg/${publicID}.jpg`;
  } else {
    return defaultbannerprofile;
  }
};

// If chunk fails retry to load.
export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          //Need to verify.. for Avoiding Load Chunk Issues
          if (retriesLeft === 3) {
            window.location.reload();
            return;
          }
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            window.location.reload();
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);

export const capitalizeFirstLetter = str =>
  str?.replace(/(?:^|\s)\S/g, a => a.toUpperCase());

// e.g. : Generate numbers range 1..10 with step of 2 = generateRange(1,10,2)
export const generateRange = (start, stop, step, isString) =>
  Array.from({length: (stop - start) / step + 1}, (_, i) =>
    isString ? `${start + i * step}` : start + i * step,
  );

export const numberInUSFormat = number => {
  const nfObject = new Intl.NumberFormat('en-US');
  return nfObject.format(number);
};

export const extractSkillsfromCourses = data => {
  let Skills = [];
  if (data && Array.isArray(data) && data?.length) {
    data.map(d => {
      d?.courses &&
        Array.isArray(d.courses) &&
        d.courses?.length &&
        d.courses.forEach(course => {
          if (course?.skills_learned?.length) {
            Skills = [...Skills, ...course.skills_learned];
          }
        });
    });
  }
  return Skills;
};

export const combinedUniqueSkillsListForProgram = pathData => {
  let CombineSkills = [];
  if (pathData?.skills_learned?.length) {
    pathData?.skills_learned.forEach(skill => {
      CombineSkills = [...CombineSkills, skill.name];
    });
  }
  if (pathData?.terms?.length) {
    CombineSkills = [
      ...CombineSkills,
      ...extractSkillsfromCourses(pathData?.terms),
    ];
  }
  if (pathData?.segment?.length) {
    CombineSkills = [
      ...CombineSkills,
      ...extractSkillsfromCourses(pathData?.segment),
    ];
  }
  if (pathData?.segment_groups?.length) {
    pathData.segment_groups.forEach(seg_group => {
      CombineSkills = [
        ...CombineSkills,
        ...extractSkillsfromCourses(seg_group?.segments),
      ];
    });
  }
  if (pathData?.ge_path_details) {
    if (pathData?.ge_path_details?.segments) {
      CombineSkills = [
        ...CombineSkills,
        ...extractSkillsfromCourses(pathData.ge_path_details.segments),
      ];
    }
    if (pathData?.ge_path_details?.segment_groups?.length) {
      pathData?.ge_path_details?.segment_groups.forEach(seg_group => {
        CombineSkills = [
          ...CombineSkills,
          ...extractSkillsfromCourses(seg_group?.segments),
        ];
      });
    }
  }
  return uniq(CombineSkills);
};

export const skillsLearned = (
  pathData,
  showSkillsOnlyFromOverview = null,
  onShowAllLess,
  isShowAll = true,
) => {
  let CombineSkills = [];
  if (!showSkillsOnlyFromOverview) {
    CombineSkills = combinedUniqueSkillsListForProgram(pathData);
  }

  if (pathData?.skills_learned?.length && showSkillsOnlyFromOverview) {
    CombineSkills = pathData.skills_learned.map(sk => sk.name);
  }

  if (CombineSkills?.length) {
    const UNIQ_SKILLS = uniq(CombineSkills);
    const UNIQ_SKILLS_LENGTH = UNIQ_SKILLS?.length;
    return {
      skillsComponent: (
        <>
          <ul className='liSkillLearned'>
            {onShowAllLess
              ? UNIQ_SKILLS.map((skill, idx) => {
                  if (!isShowAll) {
                    if (idx < 15) {
                      return <li key={`skill-${idx}`}>{skill}</li>;
                    }
                  } else {
                    return <li key={`skill-${idx}`}>{skill}</li>;
                  }
                })
              : UNIQ_SKILLS.map((skill, idx) => (
                  <li key={`skill-${idx}`}>{skill}</li>
                ))}
          </ul>
          {onShowAllLess && UNIQ_SKILLS_LENGTH > 15 && (
            <span onClick={onShowAllLess} className='anc_showall'>
              {isShowAll ? 'Show Less' : 'Show All'}
            </span>
          )}
        </>
      ),
      skillsCount: UNIQ_SKILLS_LENGTH || 0,
    };
  } else {
    return {
      skillsComponent: (
        <div className='no-skills'>No skills found for this program</div>
      ),
      skillsCount: 0,
    };
  }
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const combineMenuLinksToOneBlock = FooterLinks => {
  let MenuItemsDisplay = [{...FooterLinks[0]}];
  FooterLinks.forEach((item, idx) => {
    if (idx > 0) {
      const AboutIndex = FooterLinks[0]?.links?.length - 1;
      const AboutItem = FooterLinks[0]?.links[AboutIndex];
      const nArray = [...MenuItemsDisplay[0]?.links, ...item?.links, AboutItem];
      nArray.splice(AboutIndex, 1);
      MenuItemsDisplay = [
        {
          key: MenuItemsDisplay[0].key,
          links: [...nArray],
        },
      ];
    }
  });
  return MenuItemsDisplay;
};

export const getProfileCompletedTabs = (
  profileData,
  studentCredentials,
  activePathsData,
) => {
  const profile = profileData?.data || {};
  const documents = profile?.documents || [];
  const {data: credentials = []} = studentCredentials;
  const studentDetails = profile?.student_details || {};
  const educations = credentials?.filter(
    i => i.credential_type === 'education',
  );
  const activePaths =
    activePathsData &&
    Array.isArray(activePathsData) &&
    activePathsData.length > 0 &&
    activePathsData.filter(
      ({completed_stage}) => completed_stage === 'complete',
    );
  const courses = credentials?.filter(i => i.credential_type === 'course');
  const certifications = credentials?.filter(
    i => i.credential_type === 'certification',
  );
  const portfolio_documents = documents.filter(
    doc => doc.doc_type === 'portfolio_document',
  );
  const related_document = documents.filter(
    doc => doc.doc_type === 'related_document',
  );

  const transcript = documents.filter(doc => doc.doc_type === 'transcript');

  const sections = {};
  // name section
  // if (
  //   studentDetails.first_name ||
  //   studentDetails.last_name ||
  //   studentDetails.middle_name
  // )
  //   sections['name'] = true;

  // details section
  if (
    studentDetails.first_name ||
    studentDetails.last_name ||
    studentDetails.gender ||
    studentDetails.dob ||
    studentDetails.ethnicity ||
    // studentDetails.native_language ||
    studentDetails.phone_number
    // studentDetails.address
  )
    sections['details'] = true;

  // professional

  if (profile['bio']) sections['professional'] = true;

  // education
  if (
    (educations && educations.length > 0) ||
    (activePaths && activePaths.length > 0)
  )
    sections['education'] = true;
  // courses
  if (courses && courses.length > 0) sections['courses'] = true;
  // license
  if (certifications && certifications.length > 0) sections['license'] = true;

  // work_exp
  if (profile.work_exp && profile.work_exp.length > 0)
    sections['work_exp'] = true;

  // vol_exp
  if (profile.voluntary_exp && profile.voluntary_exp.length > 0)
    sections['vol_exp'] = true;

  // awards_projects
  if (
    profile.awards_leadership_projects_publications &&
    profile.awards_leadership_projects_publications.length > 0
  )
    sections['awards_projects'] = true;

  // portfolio
  if (
    profile.portfolio_url ||
    (portfolio_documents && portfolio_documents.length > 0)
  )
    sections['portfolio'] = true;

  // trascripts
  if (transcript && transcript.length > 0) sections['trascripts'] = true;

  // resume
  if (related_document && related_document.length > 0)
    sections['resume'] = true;

  // interests
  if (profile.interest && profile.interest.length > 0)
    sections['interests'] = true;
  console.log('getProfileCompletedTabs', sections);
  return sections;
};

export const isOneOfThePropInObjHasVal = obj => {
  if (typeof obj !== 'object') return false;
  const arr = Object.keys(obj);
  for (let i = 0; i < arr.length; i++) {
    if (obj[arr[i]] || obj[arr[i]] === 0) return true;
  }
  return false;
};
export const getHrefUrl = url => {
  if (!url) return url;
  const http = url.indexOf('http://');
  const https = url.indexOf('https://');
  if (http === 0 || https === 0) return url;
  else return `http://${url}`;
};

export const uuid4 = () => {
  const ho = (n, p) => n.toString(16).padStart(p, 0); /// Return the hexadecimal text representation of number `n`, padded with zeroes to be of length `p`; e.g. `ho(13, 2)` returns `"0d"`
  const data = crypto.getRandomValues(new Uint8Array(16)); /// Fill a buffer with random bits
  data[6] = (data[6] & 0xf) | 0x40; /// Patch the 6th byte to reflect a version 4 UUID
  data[8] = (data[8] & 0x3f) | 0x80; /// Patch the 8th byte to reflect a variant 1 UUID (version 4 UUIDs are)
  const view = new DataView(data.buffer); /// Create a view backed by the 16-byte buffer
  return `${ho(view.getUint32(0), 8)}-${ho(view.getUint16(4), 4)}-${ho(
    view.getUint16(6),
    4,
  )}-${ho(view.getUint16(8), 4)}-${ho(view.getUint32(10), 8)}${ho(
    view.getUint16(14),
    4,
  )}`; /// Compile the canonical textual form from the array data
};

export const handleSignUpNewUserURL = () => {
  addQueryParamToURL('newUser', 'true');
  setTimeout(() => {
    removeQueryParamToURL('newUser');
  }, 7000);
};

export const pushNewUserEvents = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'user_sign_up',
  });
};

export const getURLQueryParams = key => {
  var currentUrl = window.location.href;
  var url = new URL(currentUrl);
  return url.searchParams.get(key);
};

export const addQueryParamToURL = (key, value) => {
  if (key && value) {
    // Get the current URL
    var currentUrl = window.location.href;

    // Create a new URL object
    var url = new URL(currentUrl);

    // Add query parameters
    if (!url.searchParams.get(key)) url.searchParams.append(key, value);

    // Replace the current URL with the modified URL (without page refresh)
    window.history.replaceState({}, '', url);
  }
};

export const addMultiQueryParamsToURL = array => {
  if (array && array.length > 0) {
    // Get the current URL
    var currentUrl = window.location.href;

    // Create a new URL object
    var url = new URL(currentUrl);

    // Add query parameters
    array.forEach(({key, value}) => {
      if (!url.searchParams.get(key)) url.searchParams.append(key, value);
      else url.searchParams.set(key, value);
    });

    // Replace the current URL with the modified URL (without page refresh)
    window.history.replaceState({}, '', url);
  }
};

export const removeQueryParamToURL = key => {
  if (key) {
    // Get the current URL
    var currentUrl = window.location.href;

    // Create a new URL object
    var url = new URL(currentUrl);

    // Add query parameters
    if (typeof key === 'string') url.searchParams.delete(key);
    if (Array.isArray(key)) {
      key.forEach(k => {
        url.searchParams.delete(k);
      });
    }

    // Replace the current URL with the modified URL (without page refresh)
    window.history.replaceState({}, '', url);
  }
};
export const updateQueryParams = paramsToUpdate => {
  // params = {key: value}
  // Get current query parameters
  const url = new URL(window.location);

  // Update query parameters with new values
  for (const key in paramsToUpdate) {
    url.searchParams.set(key, paramsToUpdate[key].replace(/ /g, '-'));
  }
  window.history.pushState({}, '', url);
};
