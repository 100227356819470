// hooks
import {useInstance} from 'core/hooks';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useState, useEffect, useCallback, useMemo, useRef} from 'react';
// redux
import {fetchAllJobs, getAllJobs, clearAllJobs} from 'redux/modules/jobs';
// components
import Filter from './Filters';
import {DataGrid} from 'mui-core';
import {Link} from 'react-router-dom';
import LayoutHeader from '../Layout/LayoutHeader';
import LayoutFilter from '../Layout/LayoutFilter';
import {Box, Typography, useTheme, useMediaQuery} from '@mui/material';
// utils
import {
  queryStringParse,
  numberInUSFormat,
  capitalizeFirstLetter,
} from 'core/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import {exploreCareer} from 'data/exploreCareer.json';
import {employOpportData} from 'data/occupation.json';
import {
  addInputHistoryItem,
  getInputHistory,
} from 'redux/modules/general/inputHistory';

const JobOpportunities = ({
  title,
  target = '_blank',
  scrollTop = 750,
  // fixedScrollHeight,
  programTitle = '',
  relatedSearch = [],
  businessPartnerName,
  globalSearch = '',
  dashboardReferer,
}) => {
  // hooks
  const theme = useTheme();
  const filterRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    data: {
      nearby_msa = [],
      super_type_name = null,
      default_msa: {city = '', state_code = ''} = {},
    } = {},
  } = useInstance();
  // selectors
  const allJobs = useSelector(getAllJobs);
  // utils
  const {defaultLocation} = exploreCareer;
  /* Commenting out as requirement changed for default location as Uinted state */
  // const defaultLoc = super_type_name
  //   ? `${city ? city + ', ' : ''}${state_code}`
  //   : defaultLocation;
  const defaultLoc = defaultLocation;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultReqParams = {
    page: 1,
    page_size: 10,
    sort: 'relevant',
    company_name: businessPartnerName,
  };
  const defaultReqParamsForSkills = {
    page: 1,
    min_perct: 0,
    page_size: 10,
    skills_option: true,
    sort: 'skills_match_desc',
  };
  const {
    location: {search},
  } = history;
  const {job_type = 'job'} = queryStringParse(search);
  // states
  const [filters, setFilters] = useState({
    job_type: false,
    search: job_type,
    title: programTitle,
  });
  const [reqParams, setReqParams] = useState(
    dashboardReferer ? defaultReqParamsForSkills : defaultReqParams,
  );
  const [initialFilters, setInitialFilters] = useState(filters);
  const {request: allJobsRequest, data: allJobsData} = allJobs || {};
  const TotalRecords =
    (allJobsData && allJobsData?.page_details?.total_results) || 0;

  const columns = useMemo(
    () => [
      {
        editable: false,
        filterable: false,
        field: 'job_title',
        headerName: 'Name',
        flex: businessPartnerName ? 0.4 : 0.35,
        renderCell: (record = {}) => {
          const {job_id, job_title = '-', company, job_source, job_type} =
            record?.row || {};
          // jobId=9b9ef3ef-da3d-43da-86bd-f41b30f2d39e&title=angular&company=Anoop%2520TB&source=local
          let path = `/opportunities/jobs?jobId=${job_id}&title=${job_title}&company=${company}&source=${job_source}`;
          if (job_type === 'apprenticeship') {
            path = `/opportunities/apprenticeships/${encodeURIComponent(
              job_title,
            )}/${encodeURIComponent(company)}`;
          }
          return (
            <Link
              title={`${job_title} `}
              style={{
                color:
                  theme.palette.mode === 'light'
                    ? theme.palette.secondary.dark
                    : theme.palette.secondary.light,
              }}
              tabIndex={0}
              to={path}
              target={target}>
              {job_title}
            </Link>
          );
        },
      },
      {
        editable: false,
        flex: businessPartnerName ? 0.15 : 0.25,
        sortable: businessPartnerName ? false : true,
        field: businessPartnerName ? 'job_type' : 'company',
        headerName: businessPartnerName ? 'Type' : 'Company',
        valueFormatter: params => capitalizeFirstLetter(params.value),
        renderCell: params => (
          <div title={`${params.value} `}>
            {capitalizeFirstLetter(params.value)}
          </div>
        ),
      },
      {
        editable: false,
        field: 'location',
        flex: businessPartnerName ? 0.3 : 0.25,
        headerName: employOpportData.locationGridTxt,
        renderCell: params => (
          <div title={`${params.value} `}>{params.value}</div>
        ),
      },
      {
        flex: isMobile ? 0.3 : 0.15,
        editable: false,
        field: 'date_posted',
        headerName: employOpportData.datePostedGridTxt,
        renderCell: (record = {}) => {
          const {date_posted} = record?.row || {};
          let displayDate = '-';
          if (date_posted) {
            // check for valid ISO String.......................
            if (moment(date_posted, moment.ISO_8601, true).isValid()) {
              displayDate = moment(date_posted).format('MM/DD/YYYY');
            } else {
              // Additional check is rrquired for the cross-browser fix
              //Note:: regex require to work in Safari browser
              const dateToIso = new Date(
                Date.parse(date_posted.replace(/-/g, '/')),
              ).toISOString();
              displayDate = moment(dateToIso).format('MM/DD/YYYY');
            }
          }
          return displayDate;
        },
      },
      {
        editable: false,
        hide: !dashboardReferer,
        field: 'skill_match_percentage',
        flex: businessPartnerName ? 0.25 : 0.15,
        headerName: employOpportData.skillMatchGridTxt,
        renderCell: params => (
          <div title={`${params.value} `}>{Math.round(params.value || 0)}%</div>
        ),
      },
    ],
    [businessPartnerName, isMobile, target, theme], // eslint-disable-line
  );
  useEffect(() => {
    dispatch(getInputHistory());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    let updatedFilters = {
      job_type: false,
      search: job_type,
      title: programTitle,
      location: businessPartnerName ? '' : defaultLoc,
    };
    setInitialFilters(updatedFilters);
    refetchData(reqParams, updatedFilters);
    return () => {
      dispatch(clearAllJobs());
    };
  }, [programTitle]); // eslint-disable-line react-hooks/exhaustive-deps

  const apiCall = useCallback(
    (Obj, filters = null) => {
      dispatch(fetchAllJobs(Obj, filters));
    },
    [filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onPageChange = useCallback(
    page => {
      const Obj = Object.assign({}, reqParams, {
        page: page + 1,
      });
      refetchData(Obj, filters);
      window.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      });
    },
    [reqParams, filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onPageSizeChange = useCallback(
    pageSize => {
      const Obj = Object.assign({}, reqParams, {page: 1, page_size: pageSize});
      refetchData(Obj, filters);
      window.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      });
    },
    [filters, reqParams], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onSortChange = useCallback(
    sortModel => {
      const sortValue = getSortValue(sortModel);
      const Obj = Object.assign({}, reqParams, {page: 1, sort: sortValue});
      refetchData(Obj, filters);
      window.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      });
    },
    [filters, reqParams], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getSortValue = useCallback(sortModel => {
    let sortValue = dashboardReferer ? 'date_desc' : 'relevant';
    if (sortModel.length > 0) {
      const sortObject = sortModel[0];
      switch (sortObject.field) {
        case 'date_posted': {
          return `date_${sortObject.sort}`;
        }
        case 'job_title': {
          return `title_${sortObject.sort}`;
        }
        case 'location': {
          return `location_${sortObject.sort}`;
        }
        case 'company': {
          return `company_${sortObject.sort}`;
        }
        case 'skill_match_percentage': {
          return `skills_match_${sortObject.sort}`;
        }
        default:
          return dashboardReferer ? 'date_desc' : 'relevant';
      }
    }
    return sortValue;
  });

  const refetchData = (Obj, filters = null) => {
    setReqParams(Obj);
    setFilters(filters);
    apiCall(Obj, filters);
  };

  const onFilterSubmit = filters => {
    if (filters.title)
      dispatch(
        addInputHistoryItem(
          {...filters, id: filters.title},
          'jobs',
          item =>
            item.title === filters.title && item.location === filters.location,
        ),
      );
    let updatedFilters = {...filters};
    updatedFilters = {...filters, location: filters?.location};
    refetchData({...reqParams, page: 1}, updatedFilters);
    window.scrollTo({
      top: scrollTop,
      behavior: 'smooth',
    });
    if (filterRef && filterRef.current && filterRef.current.closeFilterDialog) {
      filterRef.current.closeFilterDialog();
    }
  };

  const onClear = () => {
    setReqParams({...reqParams, page: 1});
    setFilters({...filters, location: ''});
  };

  const onBlur = () => {
    refetchData(
      {...reqParams, page: 1},
      {...filters, location: initialFilters.location},
    );
  };

  const onReset = () => {
    refetchData({...reqParams, page: 1}, initialFilters);
    window.scrollTo({
      top: scrollTop,
      behavior: 'smooth',
    });
  };

  return (
    <Box>
      <LayoutHeader title={title} />
      <LayoutFilter
        ref={filterRef}
        title='Filters'
        existFilter={filters}
        loading={allJobsRequest}
        // fixedScrollHeight={fixedScrollHeight}
        filterContent={
          <Filter
            onBlur={onBlur}
            onReset={onReset}
            onClear={onClear}
            filters={filters}
            dashboardReferer={dashboardReferer}
            history={history}
            globalSearch={globalSearch}
            relatedSearch={relatedSearch}
            onFilterSubmit={onFilterSubmit}
            businessPartnerName={businessPartnerName}
            nearbyMSA={nearby_msa?.map((l, idx) => {
              return {
                ...l,
                id: idx + 1,
                nearby_msa: 'Nearby Locations',
              };
            })}
          />
        }>
        <Box minHeight={500}>
          <Typography m={2} variant='body1' color='text.primary'>
            {`${numberInUSFormat(TotalRecords)} Results`}
          </Typography>
          <DataGrid
            sx={{border: 'none'}}
            columns={columns}
            pagination={true}
            hideFooter={false}
            sortingMode='server'
            paginationMode='server'
            loading={allJobsRequest}
            onPageChange={onPageChange}
            totalRowCount={TotalRecords}
            rows={allJobsData?.data || []}
            pageSize={reqParams?.page_size}
            onSortModelChange={onSortChange}
            rowsPerPageOptions={[10, 20, 30]}
            onPageSizeChange={onPageSizeChange}
            page={reqParams?.page ? reqParams.page - 1 : 0}
            getRowId={params => params.key || params.job_id}
          />
        </Box>
      </LayoutFilter>
    </Box>
  );
};

JobOpportunities.propTypes = {
  title: PropTypes.string,
  target: PropTypes.string,
  scrollTop: PropTypes.number,
  programTitle: PropTypes.string,
  relatedSearch: PropTypes.array,
  globalSearch: PropTypes.string,
  // fixedScrollHeight: PropTypes.number,
  businessPartnerName: PropTypes.string,
};

export default JobOpportunities;
