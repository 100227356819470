import React, {useEffect, useState, useRef} from 'react';
import {message} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import debounce from 'lodash/debounce';
import {isArray, isEmpty, isObject, isString} from 'lodash';

import {Services} from './Services';
import {API, licenseTypeKeys} from 'config';
import {authSelector} from 'redux/modules/auth';
import {
  getAppConfig,
  getAppTheme,
  changeAppThemeMode,
} from 'redux/modules/general';
import {
  getActivePath,
  getMyPlan,
  getRecommendedPlan,
  getGenedOptions,
  getPathsToCompare,
  removePathFromCompare,
  addPathToCompareSuccess,
  fetchPathDetails,
  //fetchActivePaths,
} from 'redux/modules/pathways';
import {fetchInstance, getInstance} from 'redux/modules/instance';
import {resetAppConfig} from 'redux/modules/general';

import {
  getUser,
  fetchUserData,
  profileDataSelector,
  seekingDataSelector,
} from 'redux/modules/profile';
import PathwayModule from 'data/pathway';
import SettingsModule from 'data/settings.json';
import {
  clearAllOpportunities,
  fetchAllOpportunities,
} from 'redux/modules/opportunities';
import {getAllOpportunities} from 'redux/modules/opportunities/selectors';

const services = new Services();
const {success, warning, error} = message;

export const useThemeMode = () => {
  const theme = useSelector(getAppTheme);
  const dispatch = useDispatch();
  const setMode = mode => dispatch(changeAppThemeMode(mode));
  return [theme.mode, setMode];
};

// custom hook to get token and user
export const useAuth = () => {
  const {token, user, request = false} = useSelector(authSelector);
  const isRequesting = request;
  return [token, user, isRequesting];
};

export const useInstance = () => {
  const dispatch = useDispatch();
  const {request, error, data} = useSelector(getInstance);
  useEffect(() => {
    if (!data || (data && isObject(data) && isEmpty(data))) {
      dispatch(fetchInstance());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isCriminalJustice =
    data?.super_type_name === licenseTypeKeys?.criminalJustice;
  // if (error) {
  //   const {host, protocol} = window.location;
  //   const dotIdx = host.indexOf('.');
  //   const maindomain = host.substr(dotIdx + 1);
  //   const uri = `${protocol}//${maindomain}`;
  //   window.location.href = uri;
  //   return;
  // }
  return {request, error, data, isCriminalJustice};
};

export const useEmailTrigger = () => {
  const {token, user} = useSelector(authSelector);

  const onEmailVerify = async () => {
    const endPoint = `${API.gps.confirm_user_account}`;
    const response = await services.createUpdateRecord(
      token,
      endPoint,
      {},
      'POST',
    );
    return response;
  };
  return {onEmailVerify};
};

export const useQuery = () => {
  const {search} = useLocation();
  return new URLSearchParams(search);
};

// return yOffset onScroll
export const useScroll = () => {
  const [yOffset, setYOffset] = useState(0);
  useEffect(() => {
    window.onscroll = () => onScroll();
    const onScroll = () => {
      setYOffset(window.pageYOffset);
    };
  }, []);
  return yOffset;
};

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
    return () => {
      ref.current = null;
    };
  });
  return ref.current;
};

export const useComparePaths = (pathData, haveToFetch = false) => {
  const comparePathsList = useSelector(getPathsToCompare);
  const dispatch = useDispatch();
  if (pathData) {
    const {
      title,
      institute_details: {name} = {},
      award_type_name,
      program,
    } = pathData;
    const isPathExistInCompareList =
      comparePathsList &&
      isArray(comparePathsList) &&
      comparePathsList.length > 0 &&
      comparePathsList.find(path => path.uuid === pathData.uuid);

    const onCompareClick = response_value => {
      if (isPathExistInCompareList) {
        if (response_value === 'remove') {
          dispatch(removePathFromCompare(pathData));
        } else {
          warning(PathwayModule.PathAlreadyAdded);
        }
        return;
      }
      comparePathsList &&
      isArray(comparePathsList) &&
      comparePathsList.length <= 2
        ? haveToFetch
          ? dispatch(
              fetchPathDetails(
                name,
                title,
                award_type_name,
                program,
                'addToCompare',
              ),
            )
          : dispatch(addPathToCompareSuccess(pathData))
        : warning(PathwayModule.AddComparePathValidationMsg);
    };
    return {onCompareClick, isPathExistInCompareList, comparePathsList};
  }
  return {};
};

export const useProfileUpload = key => {
  const {data} = useSelector(profileDataSelector);
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(false);
  const userData = useUser();

  const uploadImage = async options => {
    const {onSuccess, onError, file, onProgress} = options;

    const fmData = new FormData();
    const config = {
      headers: {'content-type': 'multipart/form-data'},
      onUploadProgress: event => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 500);
        }
        onProgress({percent: (event.loaded / event.total) * 100});
      },
    };
    fmData.append(key, file);
    try {
      const endPoint = `${API.gps.student_details}/${data.student_uuid}`;
      const res = await axios.patch(endPoint, fmData, config);
      onSuccess('Ok');
      if (res && res.status === 200 && res.data) {
        await dispatch(fetchUserData());
        setProgress(false);
      }
    } catch (err) {
      const error = new Error('Some error');
      setProgress(false);
      onError({err});
    }
  };

  return [progress, userData, uploadImage];
};
// MUI dashboard image upload Start
export const MuiProfileUpload = key => {
  const {data} = useSelector(profileDataSelector);
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(false);
  const userData = useUser();
  const updatedUserData = {
    ...userData,
  };

  const uploadImage = async options => {
    const fmData = new FormData();
    const config = {
      headers: {'content-type': 'multipart/form-data'},
      onUploadProgress: progressEvent => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        if (percentage !== undefined) setProgress(percentage);
      },
    };
    fmData.append(key, options);
    const stduid = data && data.student_uuid;
    try {
      const endPoint = `${API.gps.student_details}/${stduid}`;
      const res = await axios.patch(endPoint, fmData, config);
      if (res && res.status === 200 && res.data) {
        await dispatch(fetchUserData());
        setProgress(false);
      }
    } catch (err) {
      const error = new Error('Some error');
      setProgress(false);
    }
  };

  return [progress, updatedUserData, uploadImage];
};
// MUI dashboard image upload End
export const useSaveProfileLoader = (request, onProfileDataSubmit) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!request) {
      setIsLoading(false);
    }
    return () => {};
  }, [request]);

  const onSubmit = (key, ep, data, method, id) => {
    setIsLoading(true);
    return onProfileDataSubmit(key, ep, data, method, id);
  };

  return [isLoading, onSubmit];
};

export const useUser = () => {
  const data = useSelector(getUser);
  return data;
};

export const useSeeking = () => useSelector(seekingDataSelector);

//Pathways :
export const useActivePath = () => {
  const data = useSelector(getActivePath);
  return data;
};

export const useMyPlan = () => {
  const data = useSelector(getMyPlan);
  return data;
};

export const useRecommendedPlan = () => {
  const data = useSelector(getRecommendedPlan);
  return data;
};

export const useGenEdOptions = () => {
  const data = useSelector(getGenedOptions);
  return data;
};

export const useAutoTabLoginLogout = () => {
  const WIN = window;
  useEffect(() => {
    WIN.addEventListener(
      'storage',
      event => {
        if (event.key === 'auth') {
          WIN.location.reload();
        }
      },
      false,
    );
    return () => {
      WIN.removeEventListener('auth');
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useWindowWidth = (delay = 700) => {
  const WIN = window;
  const dispatch = useDispatch();
  const [width, setWidth] = useState(WIN.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(WIN.innerWidth);
    const debouncedHandleResize = debounce(handleResize, delay);
    WIN.addEventListener('resize', debouncedHandleResize);
    return () => {
      WIN.removeEventListener('resize', debouncedHandleResize);
    };
  }, [WIN, delay]);

  const isMobileView = width <= 768 ? true : false;
  dispatch(resetAppConfig(isMobileView, width));
  return {
    isMobileView,
    width,
  };
};

export const useCheckMobileView = () => {
  const {isMobileView} = useSelector(getAppConfig) || {};
  return {
    isMobileView,
  };
};

export const useEnableDisableScroll = () => {
  const {isMobileView} = useSelector(getAppConfig);
  const [isFilterVisible, setIsFilterVisible] = useState(
    isMobileView ? false : true,
  );

  useEffect(() => {
    setIsFilterVisible(isMobileView ? false : true);
  }, [isMobileView]); // eslint-disable-line react-hooks/exhaustive-deps

  const disableScroll = () => {
    // let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    // let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    window.onscroll = () => {
      window.scrollTo(0, 0);
    };
  };

  const enableScroll = () => {
    window.onscroll = () => {};
  };

  const onFilterClick = () => {
    if (!isFilterVisible) {
      window.scrollTo(0, 0);
      //disableScroll();
    } else {
      enableScroll();
    }
    setIsFilterVisible(!isFilterVisible);
  };

  return {
    isFilterVisible,
    onFilterClick,
    setIsFilterVisible,
  };
};

// Utility
export const prepareCredentialsFromActivePaths = data => {
  let credentialsFromActivePaths = [];
  data &&
    Array.isArray(data) &&
    data.length > 0 &&
    data
      .filter(({completed_stage}) => completed_stage === 'complete')
      .map(
        ({
          path_details,
          updated_at,
          student_onboard_uuid,
          gpa,
          comments,
          start_date,
          end_date,
          is_present,
        }) => {
          let credObj = {};
          let year = null,
            month = null;
          if (updated_at && isString(updated_at)) {
            const _date = new Date(updated_at);
            year = _date && _date.getFullYear();
            month = _date && _date.getMonth() + 1;
          }
          credObj['gpa'] = gpa;
          credObj['is_present'] = is_present;
          credObj['comment'] = comments;
          credObj['credential_url'] = null;
          credObj['updated_at'] = updated_at;
          credObj['start_date'] = start_date;
          credObj['end_date'] = end_date;
          credObj['date_completed_year'] = year;
          credObj['path_details'] = path_details;
          credObj['date_completed_month'] = month;
          credObj['credential_type'] = 'education';
          credObj['program_name'] = path_details?.title;
          credObj['award_type'] = path_details?.award_type;
          credObj['banner'] = path_details?.banner_cloudinary;
          credObj['student_onboard_uuid'] = student_onboard_uuid;
          credObj['org_name'] = path_details?.institute_details?.name;
          credObj['skills'] =
            path_details?.skills_learned?.map(skill => skill.name) || [];
          credObj['active_path'] = true;
          credObj['area_of_focus'] = path_details?.title;
          credentialsFromActivePaths.push(credObj);
        },
      );
  return credentialsFromActivePaths;
};

const credentialFormatter = (credentials, credential_type) => {
  if (credential_type && credentials && isArray(credentials)) {
    return credentials
      .filter(credential => credential.credential_type === credential_type)
      .map(credential => {
        const updated_at = new Date(
          `${SettingsModule.months[credential?.date_completed_month - 1]} ${
            credential?.date_completed_year
          }`,
        );
        credential['updated_at'] = updated_at;
        return credential;
      });
  }
};

export const useFormatCredentailData = (credentialsInfo, activePathsData) => {
  if (credentialsInfo) {
    const getCredentialFormatedData = type => {
      if (type) {
        return credentialFormatter(credentialsInfo, type);
      }
    };
    const getCredentialsData = () => {
      if (credentialsInfo && isArray(credentialsInfo)) {
        const credentialsFromPrograms =
          (activePathsData &&
            prepareCredentialsFromActivePaths(activePathsData)) ||
          [];
        const educationCredentials = getCredentialFormatedData('education');
        const courseCredentials = getCredentialFormatedData('course');
        const licenseCredentials = getCredentialFormatedData('license');
        const certificationCredentials = getCredentialFormatedData(
          'certification',
        );
        return {
          education: [...credentialsFromPrograms, ...educationCredentials],
          licenseAndCertification: [
            ...licenseCredentials,
            ...certificationCredentials,
          ],
          course: courseCredentials,
        };
      }
    };
    return getCredentialsData();
  }
  return {};
};

// function useNeoScroll() {
//   const [lastScrollTop, setLastScrollTop] = useState(0);
//   const [bodyOffset, setBodyOffset] = useState(
//     document.body.getBoundingClientRect(),
//   );
//   const [scrollY, setScrollY] = useState(bodyOffset.top);
//   const [scrollX, setScrollX] = useState(bodyOffset.left);
//   const [scrollDirection, setScrollDirection] = useState();

//   const listener = e => {
//     setBodyOffset(document.body.getBoundingClientRect());
//     setScrollY(-bodyOffset.top);
//     setScrollX(bodyOffset.left);
//     setScrollDirection(lastScrollTop > -bodyOffset.top ? 'down' : 'up');
//     setLastScrollTop(-bodyOffset.top);
//   };

//   useEffect(() => {
//     window.addEventListener('scroll', listener);
//     return () => {
//       window.removeEventListener('scroll', listener);
//     };
//   });

//   return {
//     scrollY,
//     scrollX,
//     scrollDirection,
//   };
// }

export const useOpportunitiesData = () => {
  const dispatch = useDispatch();
  const fetchAllJobs = (reqParams, filters) => {
    dispatch(fetchAllOpportunities('job', reqParams, filters));
  };
  const fetchAllApprenticeships = (reqParams, filters, loadMore) => {
    dispatch(
      fetchAllOpportunities('apprenticeship', reqParams, filters, loadMore),
    );
  };
  const {job: jobsData, apprenticeship: apprenticeshipsData} = useSelector(
    getAllOpportunities,
  );

  const clearJobs = () => dispatch(clearAllOpportunities('job'));
  const clearApprenticeships = () =>
    dispatch(clearAllOpportunities('apprenticeship'));
  return {
    fetchAllApprenticeships,
    fetchAllJobs,
    jobsData,
    apprenticeshipsData,
    clearJobs,
    clearApprenticeships,
  };
};
