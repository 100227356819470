import * as TYPES from './constants';
//al OPPORTUNITIES
export const requestAllOpportunities = oppType => ({
  type: TYPES.ALL_OPPORTUNITIES_REQUEST,
  oppType: oppType,
});
export const recieveAllOpportunities = (json, loadMore, oppType) => ({
  type: TYPES.ALL_OPPORTUNITIES_SUCCESS,
  payload: json,
  loadMore: loadMore,
  oppType: oppType,
});
export const requestAllOpportunitiesError = (err, oppType) => ({
  type: TYPES.ALL_OPPORTUNITIES_ERROR,
  err,
  oppType: oppType,
});
export const clearAllOpportunities = oppType => ({
  type: TYPES.CLEAR_ALL_OPPORTUNITIES,
  oppType: oppType,
});
