import {styled, useTheme} from '@mui/material/styles';
import {Box, Stack, Typography} from '@mui/material';
import {Card, Button, StaticBanner, themeColorMode} from 'mui-core';
import {ArrowForward} from '@mui/icons-material';
import {
  BASE_URL,
  demoBanner,
  partnersBanner,
  transparentRing,
  multiStakeholder,
} from 'assets/images';
import {EMPLOYER_CONTACT_MAILTO} from 'config';
import {partnersPage, pageBannerData} from 'data/partnersSignup.json';

const {
  experienceRegionalDesc,
  experienceRegionalTitle,
  partneringCreateEconomic: {
    comingSoon,
    learnMoreBtnTxt,
    partnershipPath,
    partnershipList = [],
  },
  multiStakeholderSection: {title, desc, partnerList = []},
  demoRequest: {demoRequestBtn, demoRequestTitle},
} = partnersPage || {};
const {bannerCaptionTitle, partnerBannerTitle} = pageBannerData;

const Listpoints = styled(Typography)(() => ({
  '& ul': {
    margin: '0px',
    paddingInlineStart: '20px',
    '& li': {
      float: 'left',
      lineHeight: '150%',
      margin: '8px 0px',
    },
  },
}));

const GradientBox = styled(Box)(({theme}) => ({
  backgroundRepeat: 'repeat',
  backgroundBlendMode: 'difference',
  background:
    theme.palette.mode === 'dark'
      ? `
      linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%),
      url(${transparentRing}) top / 160px 160px repeat
    `
      : `
      linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%),
      url(${transparentRing}) top / 160px 160px repeat
    `,
}));

const PartnershipCard = styled(Card)(({theme, imgurl}) => ({
  width: '33%',
  height: '280px',
  maxWidth: '100%',
  borderRadius: '12px',
  position: 'relative',
  backgroundRepeat: 'no-repeat',
  backgroundBlendMode: 'normal, color, normal',
  boxShadow: '0px 5px 24px 0px rgba(0, 0, 0, 0.08)',
  background: `linear-gradient(180deg, rgba(39, 17, 95, 0.10) 0%, #27115F 100%),
               linear-gradient(0deg, var(--Colors-Brand-950, #27115F) 0%, var(--Colors-Brand-950, #27115F) 100%),
               url(${BASE_URL}${imgurl}) lightgray 50% / cover no-repeat`,

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '460px',
  },
}));

const Partners = ({history}) => {
  const theme = useTheme();

  return (
    <Box>
      <StaticBanner url={partnersBanner} captionTitle={bannerCaptionTitle}>
        <Typography
          variant='h1'
          sx={{mt: '-6px !important'}}
          color={theme.palette.grey.contrastText}
          dangerouslySetInnerHTML={{__html: partnerBannerTitle}}
        />
      </StaticBanner>
      <Box
        bgcolor={themeColorMode(
          theme,
          theme.palette.background.paper,
          theme.palette.darkGray.main,
        )}>
        <Stack gap={{xs: 2, md: 3}} p={{xs: 4, md: 9}} alignItems='flex-start'>
          <Typography
            variant='h1'
            textAlign='left'
            color='text.primary'
            dangerouslySetInnerHTML={{
              __html: experienceRegionalTitle,
            }}
          />
          <Typography variant='desc25' textAlign='left' color='text.primary'>
            {experienceRegionalDesc}
          </Typography>
        </Stack>
      </Box>
      <Stack
        gap={7}
        p={{xs: 4, md: 9}}
        pt='0px !important'
        textAlign='center'
        id='partnershipPath'
        color='text.primary'
        bgcolor={themeColorMode(
          theme,
          theme.palette.midGray.contrastText,
          theme.palette.darkGray.main,
        )}>
        <Typography variant='h1' color='text.primary' textAlign='left'>
          {partnershipPath}
        </Typography>
        <Stack
          mt={-4}
          gap={4}
          alignItems='center'
          justifyContent='flex-start'
          direction={{sm: 'column', md: 'row'}}>
          {Array.isArray(partnershipList) &&
            partnershipList.length &&
            partnershipList.map(
              ({partnerBanner, partnerTitle, path}, index) => (
                <PartnershipCard
                  key={index}
                  color='#6938EF'
                  imgurl={partnerBanner}>
                  <Box left='5%' bottom='30%' position='absolute'>
                    <Typography
                      variant='h4'
                      textAlign='left'
                      color={theme.palette.grey.contrastText}>
                      {partnerTitle}
                    </Typography>
                  </Box>
                  <Box
                    right='10%'
                    bottom='30px'
                    textAlign='right'
                    position='absolute'>
                    <Button
                      variant='contained'
                      disabled={index !== 0}
                      onClick={() => path && history.push(path)}
                      endIcon={index === 0 && <ArrowForward sx={{mb: '2px'}} />}
                      sx={{
                        color: theme.palette.dialogDarkBG.main,
                        borderColor: theme.palette.grey.contrastText,
                        background: `${
                          theme.palette.mode === 'light'
                            ? theme.palette.background.paper
                            : theme.palette.grey.contrastText
                        } !important`,
                        '&.Mui-disabled': {
                          color: theme.palette.midGray.main,
                          backgroundColor: `${theme.palette.grey.contrastText} !important`,
                        },
                      }}>
                      {index !== 0 ? comingSoon : learnMoreBtnTxt}
                    </Button>
                  </Box>
                </PartnershipCard>
              ),
            )}
        </Stack>
      </Stack>
      <GradientBox p={{xs: 4, md: 9}}>
        <Stack
          mb={9}
          gap={9}
          alignItems='center'
          direction={{xs: 'column', md: 'row'}}>
          <Box maxWidth={{md: '450px', lg: '700px'}} maxHeight='480px'>
            <img
              width='100%'
              height='100%'
              alt='Partnership-Path'
              src={multiStakeholder}
              style={{
                borderRadius: '12px',
                boxShadow: '0px 24px 48px -12px rgba(16, 24, 40, 0.35)',
              }}
            />
          </Box>
          <Stack gap={{xs: 4, md: 6}}>
            <Typography
              variant='h1'
              color='text.primary'
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            <Typography
              variant='desc25'
              color='text.primary'
              textAlign='justify'>
              {desc}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          gap={4}
          justifyContent='center'
          alignItems='flex-starts'
          direction={{sm: 'column', md: 'row'}}>
          {Array.isArray(partnerList) &&
            partnerList.length &&
            partnerList.map(({icon, partnerTitle, partnerPoints}, index) => (
              <Stack gap={4} key={index}>
                <img
                  width='130px'
                  height='130px'
                  alt='partnerList'
                  src={`${BASE_URL}/${icon}`}
                />
                <Typography variant='h4' color='text.primary'>
                  {partnerTitle}
                </Typography>
                <Listpoints
                  mt={-3}
                  variant='bodyXL'
                  color={themeColorMode(
                    theme,
                    theme.palette.grayText.main,
                    theme.palette.grayLight.main,
                  )}
                  dangerouslySetInnerHTML={{
                    __html: partnerPoints,
                  }}
                />
              </Stack>
            ))}
        </Stack>
      </GradientBox>
      <StaticBanner
        url={demoBanner}
        bgGradient='rgb(0 0 0 / 6%)'
        backgroundImgPosition='center'>
        <Typography
          variant='h1'
          color={theme.palette.grey.contrastText}
          dangerouslySetInnerHTML={{__html: demoRequestTitle}}
        />
        <Button
          p={1}
          endIcon={<ArrowForward />}
          href={EMPLOYER_CONTACT_MAILTO}
          sx={{
            color: 'white',
            width: 'fit-content',
            mt: {xs: 1, md: '8px !important'},
            backgroundColor: theme.palette.primary.light,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
          }}>
          {demoRequestBtn}
        </Button>
      </StaticBanner>
    </Box>
  );
};

export default Partners;
