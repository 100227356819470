import React, {useState, useEffect, useCallback} from 'react';
// utils
import {
  StaticBanner,
  AppBreadcrumb,
  StatsWithIcon,
  BackgroundPaper,
  SearchFilter,
} from 'mui-core';
import {jobsBanner} from 'assets/images';
import jobsStaticData from 'data/jobs.json';
import {useTheme} from '@mui/material/styles';
import {Box, Stack, Divider, Container, Typography} from '@mui/material';
// components
import JobLayout from './Layout';
import FilterForm from './components/FilterForm';
//icons
import BusinessIcon from '@mui/icons-material/Business';
import SuitcaseIcon from '@mui/icons-material/BusinessCenter';
import {useInstance, useOpportunitiesData} from 'core/hooks';

const {
  bannerData: {
    ribbonText,
    bannerDesc,
    bannerTitle,
    jobsAvailLabel,
    jobsAvailCount,
    companyHiringLabel,
    companyHiringCount,
  },
  breadcrumbData,
  defaultLocation,
} = jobsStaticData || {};

const defaultParams = {
  page: 1,
  page_size: 16,
  sort: 'date_desc',
};

const Jobs = ({
  tabPage = false,
  company = '',
  title = '',
  subTitle = '',
  search_query = '',
}) => {
  const theme = useTheme();
  const [filters, setFilters] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [reqParams, setReqParams] = useState(defaultParams);
  const {fetchAllJobs, clearJobs} = useOpportunitiesData();
  const [initialFilters, setInitialFilters] = useState(filters);
  const {
    data: {
      nearby_msa = [],
      super_type_name = null,
      default_msa: {city = '', state_code = ''} = {},
    } = {},
  } = useInstance();
  const defaultLoc = super_type_name
    ? `${city ? city + ', ' : ''}${state_code}`
    : defaultLocation;

  const apiCall = (Obj, filters = null) => {
    fetchAllJobs(Obj, filters);
  };

  const refetchData = (Obj, newfFilters, loadMore) => {
    setReqParams(Obj);
    const updatedFilters = {...filters, ...newfFilters};
    setFilters(updatedFilters);
    apiCall(Obj, updatedFilters, loadMore);
  };

  const onPageChange = page => {
    window.scrollTo({top: 660, behavior: 'smooth'});
    const Obj = Object.assign({}, reqParams, {
      page,
    });
    refetchData(Obj, filters, true);
  };

  useEffect(() => {
    let updatedFilters = {
      ...filters,
      location: defaultLoc,
      company_name: company,
    };
    if (search_query) {
      updatedFilters.title = search_query;
    }
    setInitialFilters(updatedFilters);
    refetchData(reqParams, updatedFilters);
    return () => {
      clearJobs();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = useCallback(
    values => {
      let filterApply = false;
      if (values) {
        const filterKeys = Object.keys(values);
        filterKeys.forEach(key => {
          if (values[key] || values[key] === '') {
            filterApply = true;
          }
          if (key === 'no_of_days') {
            values[key] = parseInt(values[key]);
            if (values[key] === 0) {
              values[key] = null;
            }
            filterApply = true;
          }
        });
        if (filterApply) {
          const Obj = Object.assign({}, reqParams, {
            sort: 'datedesc',
            page: 1,
          });
          refetchData(Obj, values);
          onDrawerClose();
        }
      }
    },
    [reqParams, filters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onFilterReset = resetObj => {
    const Obj = {...defaultParams};
    refetchData(Obj, {...resetObj});
    onDrawerClose();
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  return (
    <BackgroundPaper>
      {!tabPage ? (
        <>
          <Container maxWidth='xl'>
            <AppBreadcrumb showHome={false} dataList={breadcrumbData} />
          </Container>
          <StaticBanner
            title={bannerTitle}
            desc={bannerDesc}
            url={jobsBanner}
            ribbonTxt={ribbonText}>
            <Stack direction='row' gap='15px'>
              <StatsWithIcon
                data={[
                  {
                    title: jobsAvailLabel,
                    count: jobsAvailCount,
                    icon: <SuitcaseIcon style={{color: 'white'}} />,
                  },
                  {
                    title: companyHiringLabel,
                    count: companyHiringCount,
                    icon: <BusinessIcon style={{color: 'white'}} />,
                  },
                ]}
              />
            </Stack>
          </StaticBanner>
        </>
      ) : (
        <Container maxWidth='xl'>
          <Typography variant='h3' color='text.primary'>
            {title}
          </Typography>
          <Typography variant='bodyxlarge'>{subTitle}</Typography>
          <Divider sx={{mt: 2}} />
        </Container>
      )}
      <SearchFilter
        name='title'
        filters={filters}
        onReset={onFilterReset}
        onDrawerOpen={onDrawerOpen}
        searchPlaceholder='Search by job title or other keyword'
        onSearch={value => onFinish(value)}
      />
      <FilterForm
        filters={filters}
        hideCompanyFilter={tabPage}
        initialFilters={initialFilters}
        nearbyMSA={nearby_msa?.map((l, idx) => {
          return {
            ...l,
            id: idx + 1,
            nearby_msa: 'Nearby Locations',
          };
        })}
        onFinish={onFinish}
        onDrawerClose={onDrawerClose}
        onFilterReset={onFilterReset}
        isDrawerOpen={isDrawerOpen}
      />
      <Container maxWidth='xl'>
        <JobLayout
          pageOptions={defaultParams}
          onPageChange={onPageChange}
          reqParams={reqParams}
        />
      </Container>
    </BackgroundPaper>
  );
};

export default Jobs;
